import memoryStorage from './memoryStorage';

type StorageType = 'localStorage' | 'sessionStorage';

const isStorageAvailable = (type: StorageType): boolean => {
  try {
    const storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

// Get the best available storage
const getStorage = (): Storage => {
  // Always prefer localStorage for persistence
  if (isStorageAvailable('localStorage')) {
    return window.localStorage;
  }
  // Fallback to sessionStorage
  if (isStorageAvailable('sessionStorage')) {
    return window.sessionStorage;
  }
  // Last resort: in-memory storage
  return memoryStorage;
};

const storage = {
  get: (key: string): string | null => {
    try {
      const value = getStorage().getItem(key);
      if (!value) return null;
      
      // Validate JSON if stored value is an object
      try {
        JSON.parse(value);
      } catch {
        // If it's not JSON, return as is
      }
      
      return value;
    } catch (error) {
      console.warn(`Error getting item ${key} from storage:`, error);
      return null;
    }
  },

  set: (key: string, value: string): boolean => {
    try {
      // Ensure clean data before storing
      const storage = getStorage();
      storage.setItem(key, value);
      
      // Verify storage was successful
      const storedValue = storage.getItem(key);
      if (storedValue !== value) {
        throw new Error('Storage verification failed');
      }
      
      return true;
    } catch (error) {
      console.warn(`Error setting item ${key} in storage:`, error);
      return false;
    }
  },

  remove: (key: string): boolean => {
    try {
      getStorage().removeItem(key);
      return true;
    } catch (error) {
      console.warn(`Error removing item ${key} from storage:`, error);
      return false;
    }
  },

  clear: (): boolean => {
    try {
      getStorage().clear();
      return true;
    } catch (error) {
      console.warn('Error clearing storage:', error);
      return false;
    }
  },

  // Helper method to check if a key exists
  exists: (key: string): boolean => {
    try {
      return getStorage().getItem(key) !== null;
    } catch {
      return false;
    }
  }
};

export default storage;
