import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { useDebounce } from '@/hooks/useDebounce';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Search, Plus, MoreHorizontal } from 'lucide-react';
import { templateCategoryService } from '@/services/template-category.service';
import { TemplateCategoryDto, TemplateCategoryQueryParams } from '@/dtos/template-category.dto';
import { cn } from '@/lib/utils';
import { formatDate } from '@/utils/date';

interface DeleteDialogState {
  open: boolean;
  categoryId: number | null;
  loading: boolean;
}

export default function TemplateCategoriesPage() {
  const [categories, setCategories] = useState<TemplateCategoryDto[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteDialog, setDeleteDialog] = useState<DeleteDialogState>({
    open: false,
    categoryId: null,
    loading: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { toast } = useToast();
  const navigate = useNavigate();

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const queryParams: TemplateCategoryQueryParams = {
        search: searchTerm,
        limit: rowsPerPage,
        page: currentPage,
        sortBy: 'name',
        sortOrder: 'asc',
      };
      const result = await templateCategoryService.getTemplateCategories(queryParams);
      setCategories(result.items);
      setTotalRows(result.meta.totalItems);
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to fetch categories. Please try again.',
        variant: 'destructive',
      });
      setCategories([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchCategories();
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm, currentPage, rowsPerPage]);

  const handleDelete = async (categoryId: number) => {
    try {
      setDeleteDialog(prev => ({ ...prev, loading: true }));
      await templateCategoryService.deleteTemplateCategory(categoryId);
      fetchCategories();
      setDeleteDialog({ open: false, categoryId: 0, loading: false });
      toast({
        title: "Success",
        description: "Category deleted successfully",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete category",
      });
    }
  };

  const columns = [
    {
      name: 'Name',
      selector: (row: TemplateCategoryDto) => row.name,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row: TemplateCategoryDto) => row.description,
      sortable: true,
    },
    {
      name: 'Created At',
      selector: (row: TemplateCategoryDto) => formatDate(row.created_at),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row: TemplateCategoryDto) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => navigate(`/admin/template-categories/${row.id}/edit`)}
            >
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              className="text-red-600"
              onClick={() => setDeleteDialog({ open: true, categoryId: row.id, loading: false })}
            >
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Template Categories</h1>
        <Button onClick={() => navigate('/admin/template-categories/create')}>
          <Plus className="mr-2 h-4 w-4" /> Add Category
        </Button>
      </div>

      <Card className="p-6">
        <div className="flex items-center mb-4">
          <Search className="h-4 w-4 mr-2 text-gray-500" />
          <Input
            placeholder="Search categories..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="max-w-sm"
          />
        </div>

        <DataTable
          columns={columns}
          data={categories}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={setRowsPerPage}
          onChangePage={setCurrentPage}
        />
      </Card>

      <Dialog open={deleteDialog.open} onOpenChange={(open) => !deleteDialog.loading && setDeleteDialog(prev => ({ ...prev, open }))}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Category</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this category? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setDeleteDialog({ open: false, categoryId: null, loading: false })}
              disabled={deleteDialog.loading}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={() => deleteDialog.categoryId && handleDelete(deleteDialog.categoryId)}
              disabled={deleteDialog.loading}
            >
              {deleteDialog.loading ? 'Deleting...' : 'Delete'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
