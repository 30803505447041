import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/hooks/use-toast';
import { templateCategoryService } from '@/services/template-category.service';
import { CreateTemplateCategoryDto, UpdateTemplateCategoryDto, TemplateCategoryDto } from '@/dtos/template-category.dto';
import { ArrowLeft } from 'lucide-react';

export default function TemplateCategoryForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<CreateTemplateCategoryDto>({
    name: '',
    description: '',
  });

  useEffect(() => {
    if (id) {
      fetchCategory();
    }
  }, [id]);

  const fetchCategory = async () => {
    try {
      if (!id) return;
      const categoryId = parseInt(id, 10);
      if (isNaN(categoryId)) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Invalid category ID",
        });
        return;
      }

      const category = await templateCategoryService.getTemplateCategory(categoryId);
      setFormData({
        name: category.name,
        description: category.description,
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch category",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (id) {
        const categoryId = parseInt(id, 10);
        if (isNaN(categoryId)) {
          throw new Error("Invalid category ID");
        }
        const updateData: UpdateTemplateCategoryDto = {
          name: formData.name,
          description: formData.description,
        };
        await templateCategoryService.updateTemplateCategory(categoryId, updateData);
        toast({
          title: 'Success',
          description: 'Category updated successfully',
        });
      } else {
        const createData: CreateTemplateCategoryDto = {
          name: formData.name,
          description: formData.description,
        };
        await templateCategoryService.createTemplateCategory(createData);
        toast({
          title: 'Success',
          description: 'Category created successfully',
        });
      }
      navigate('/admin/template-categories');
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to save category. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="container mx-auto py-10">
      <Button
        variant="ghost"
        onClick={() => navigate('/admin/template-categories')}
        className="mb-6"
      >
        <ArrowLeft className="mr-2 h-4 w-4" />
        Back to Categories
      </Button>

      <Card className="max-w-2xl mx-auto p-6">
        <h1 className="text-2xl font-bold mb-6">
          {id ? 'Edit Category' : 'Create New Category'}
        </h1>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-medium">
              Name
            </label>
            <Input
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter category name"
              required
              disabled={loading}
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="description" className="text-sm font-medium">
              Description
            </label>
            <Textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Enter category description"
              required
              disabled={loading}
              className="min-h-[100px]"
            />
          </div>

          <div className="flex justify-end space-x-4">
            <Button
              type="button"
              variant="outline"
              onClick={() => navigate('/admin/template-categories')}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={loading}>
              {loading ? 'Saving...' : id ? 'Update Category' : 'Create Category'}
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
}
