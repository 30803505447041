import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { useDebounce } from '@/hooks/useDebounce';
import { formatDate } from '@/utils/date';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Search, Plus, MoreHorizontal, Image, Loader2 } from 'lucide-react';
import { UserTableSkeleton } from "@/components/skeletons/UserTableSkeleton";
import { cardService } from '@/services/card.service';
import { CardResponseDto } from '@/dtos/card.dto';
import { cn } from '@/lib/utils';

export default function CardsPage() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState<CardResponseDto[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    cardId: null as string | null,
    loading: false,
  });

  useEffect(() => {
    fetchCards();
  }, [searchTerm, currentPage, perPage]);

  const fetchCards = async () => {
    try {
      setLoading(true);
      const result = await cardService.getCards({
        search: searchTerm,
        page: currentPage,
        limit: perPage
      });
      setCards(result.items);
      setTotalRows(result.meta.totalItems);
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to fetch cards',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!deleteDialog.cardId) return;

    try {
      setDeleteDialog(prev => ({ ...prev, loading: true }));
      await cardService.deleteCard(deleteDialog.cardId);
      toast({
        title: "Success",
        description: "Card deleted successfully",
      });
      fetchCards();
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete card",
      });
    } finally {
      setDeleteDialog({ open: false, cardId: null, loading: false });
    }
  };

  const columns = [
    {
      name: "Preview",
      cell: (row: CardResponseDto) => (
        <div className="w-16 h-16 relative rounded-lg overflow-hidden border border-border">
          {row.imageUrl ? (
            <img
              src={row.imageUrl}
              alt={row.prompt}
              className="w-full h-full object-cover"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = '/placeholder-image.png';
              }}
            />
          ) : (
            <div className="w-full h-full bg-muted flex items-center justify-center">
              <Image className="w-6 h-6 text-muted-foreground" />
            </div>
          )}
        </div>
      ),
      width: "120px",
    },
    {
      name: "Prompt",
      selector: (row: CardResponseDto) => row.prompt,
      sortable: true,
      grow: 2,
    },
    {
      name: "Message",
      selector: (row: CardResponseDto) => row.message,
      sortable: true,
      grow: 2,
    },
    {
      name: "Created At",
      selector: (row: CardResponseDto) => formatDate(row.created_at),
      sortable: true,
      width: "180px",
    },
    {
      name: "Actions",
      cell: (row: CardResponseDto) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => navigate(`/admin/cards/edit/${row.id}`)}>
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              className="text-destructive"
              onClick={() => setDeleteDialog({ open: true, cardId: row.id, loading: false })}
            >
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
      width: "80px",
      center: true,
    },
  ];

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const customStyles = {
    // Add custom styles here
  };

  if (loading) {
    return <UserTableSkeleton />;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold tracking-tight">Cards</h1>
        <Button onClick={() => navigate('/admin/cards/new')}>
          <Plus className="mr-2 h-4 w-4" /> New Card
        </Button>
      </div>

      <Card className="p-6">
        <div className="flex items-center gap-4 mb-6">
          <div className="relative flex-1 max-w-sm">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search cards..."
              className="pl-8"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <DataTable
          columns={columns}
          data={cards}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </Card>

      <Dialog open={deleteDialog.open} onOpenChange={(open) => 
        setDeleteDialog(prev => ({ ...prev, open }))
      }>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Card</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this card? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setDeleteDialog({ open: false, cardId: null, loading: false })}
              disabled={deleteDialog.loading}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={handleDelete}
              disabled={deleteDialog.loading}
            >
              {deleteDialog.loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
