import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Search, Plus, MoreHorizontal, Pencil, Trash2, Loader2, X } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import { TemplateDto, CategoryDto, TemplateFilters, templateService } from "@/services/template.service";
import { useDebounce } from "@/hooks/useDebounce";
import { Card } from "@/components/ui/card";
import NoImage from "@/assets/no-image.svg";
import { formatDate } from "@/utils/date";

export default function TemplatesPage() {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<string>("all");
  const [templates, setTemplates] = useState<TemplateDto[]>([]);
  const [categories, setCategories] = useState<CategoryDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const debouncedSearch = useDebounce(searchQuery, 500);
  const { toast } = useToast();
  const navigate = useNavigate();

  const columns = [
    {
      name: "Image",
      cell: (row: TemplateDto) => (
        <div className="relative w-20 h-20">
          <img 
            src={row.image} 
            alt={row.name} 
            className="w-full h-full object-cover rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
            onClick={() => setSelectedImage({ url: row.image, name: row.name })}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = NoImage;
            }}
          />
        </div>
      ),
      width: "120px",
    },
    {
      name: "Title",
      selector: (row: TemplateDto) => row.name,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row: TemplateDto) => row.description,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row: TemplateDto) => row.category?.name || 'Uncategorized',
      sortable: true,
    },
    {
      name: "Status",
      cell: (row: TemplateDto) => (
        <span className={`px-2 py-1 rounded-full text-sm ${row.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
          {row.isActive ? 'Active' : 'Inactive'}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row: TemplateDto) => formatDate(row.created_at),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row: TemplateDto) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => navigate(`/admin/templates/edit/${row.id}`)}
              className="text-blue-600"
            >
              <Pencil className="mr-2 h-4 w-4" />
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => handleDelete(row.id)}
              className="text-red-600"
            >
              <Trash2 className="mr-2 h-4 w-4" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ];

  const [selectedImage, setSelectedImage] = useState<{ url: string; name: string } | null>(null);

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this template?')) {
      return;
    }

    try {
      setIsLoading(true);
      await templateService.deleteTemplate(id);
      toast({
        title: "Success",
        description: "Template deleted successfully",
      });
      fetchTemplates();
    } catch (error) {
      console.error('Error deleting template:', error);
      toast({
        title: "Error",
        description: "Failed to delete template. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const categories = await templateService.getCategories();
      setCategories(categories);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to load categories",
        variant: "destructive",
      });
    }
  };

  const fetchTemplates = async () => {
    try {
      setIsLoading(true);
      const filters: TemplateFilters = {
        page,
        limit: perPage,
        search: debouncedSearch,
      };

      if (selectedCategory !== "all") {
        filters.categoryId = parseInt(selectedCategory);
      }

      const response = await templateService.getTemplates(filters);
      setTemplates(response.items);
      setTotalRows(response.meta.totalItems);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to load templates",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, [debouncedSearch, page, perPage, selectedCategory]);

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold">Templates</h1>
        <Button onClick={() => navigate('/admin/templates/new')}>
          <Plus className="mr-2 h-4 w-4" /> Add Template
        </Button>
      </div>

      <Card className="overflow-hidden p-6">
        <div className="flex gap-4 mb-6">
          <div className="flex-1">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
              <Input
                placeholder="Search templates..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-10 w-full"
              />
            </div>
          </div>
          <Select value={selectedCategory} onValueChange={setSelectedCategory}>
            <SelectTrigger className="w-[200px]">
              <SelectValue placeholder="Select category" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Categories</SelectItem>
              {categories.map((category) => (
                <SelectItem key={category.id} value={category.id.toString()}>
                  {category.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <DataTable
          columns={columns}
          data={templates}
          progressPending={isLoading}
          progressComponent={
            <div className="flex justify-center p-4">
              <Loader2 className="h-6 w-6 animate-spin" />
            </div>
          }
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </Card>

      <Dialog open={!!selectedImage} onOpenChange={() => setSelectedImage(null)}>
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle>
              {selectedImage?.name}
            </DialogTitle>
          </DialogHeader>
          <div className="relative aspect-[16/9] overflow-hidden rounded-lg">
            {selectedImage && (
              <img
                src={selectedImage.url}
                alt={selectedImage.name}
                className="w-full h-full object-contain"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = NoImage;
                }}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
