import { ApiService } from './api.service';
import {
  LoginRequestDto,
  LoginResponseDto,
  RegisterRequestDto,
  ChangePasswordRequestDto,
  ResetPasswordRequestDto,
  ResetPasswordConfirmRequestDto,
} from '@/dtos/auth.dto';
import { User } from '@/types/auth.types';
import storage from '@/utils/storage';

class AuthService extends ApiService {
  private static instance: AuthService;
  private readonly endpoint = '/admin/auth';

  private constructor() {
    super('/api');
  }

  public static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  public async login(credentials: LoginRequestDto): Promise<LoginResponseDto> {
    const response = await this.post<LoginResponseDto>(`${this.endpoint}/login`, credentials);
    if (response.access_token) {
      storage.set('access_token', response.access_token);
      storage.set('user', JSON.stringify(response.user));
    }
    return response;
  }

  public async register(data: RegisterRequestDto): Promise<LoginResponseDto> {
    const response = await this.post<LoginResponseDto>(`${this.endpoint}/register`, data);
    if (response.access_token) {
      storage.set('access_token', response.access_token);
      storage.set('user', JSON.stringify(response.user));
    }
    return response;
  }

  public async logout(): Promise<void> {
    // try {
    //   await this.post(`${this.endpoint}/logout`);
    // } catch (error) {
    //   console.error('Logout error:', error);
    // } finally {
      this.clearAuth();
    // }
  }

  public async changePassword(data: ChangePasswordRequestDto): Promise<void> {
    await this.post(`${this.endpoint}/change-password`, data);
  }

  public async requestPasswordReset(data: ResetPasswordRequestDto): Promise<void> {
    await this.post(`${this.endpoint}/reset-password`, data);
  }

  public async resetPassword(data: ResetPasswordConfirmRequestDto): Promise<void> {
    await this.post(`${this.endpoint}/reset-password/confirm`, data);
  }

  public async forgotPassword(email: string): Promise<void> {
    await this.post('/auth/forgot-password', { email });
  }

  public isAuthenticated(): boolean {
    return !!storage.get('access_token');
  }

  public getUser(): User | null {
    const userStr = storage.get('user');
    if (!userStr) return null;
    try {
      return JSON.parse(userStr);
    } catch {
      return null;
    }
  }

  public clearAuth(): void {
    storage.remove('access_token');
    storage.remove('user');
  }
}

export const authService = AuthService.getInstance();
