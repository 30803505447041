import storage from '@/utils/storage';

interface RequestConfig extends RequestInit {
  retry?: number;
  retryDelay?: number;
}

export class ApiService {
  private baseUrl: string;
  private defaultConfig: RequestConfig;

  constructor(baseUrl: string = '/api') {
    this.baseUrl = baseUrl;
    this.defaultConfig = {
      retry: 3,
      retryDelay: 1000,
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  private async delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private getAuthHeader(): HeadersInit {
    const token = storage.get('access_token');
    return token ? { Authorization: `Bearer ${token}` } : {};
  }

  private async handleResponse<T>(response: Response): Promise<T> {
    if (!response.ok) {
      if (response.status === 401) {
        storage.remove('access_token');
        window.location.href = '/login';
        throw new Error('Session expired. Please login again.');
      }

      const error = await response.json().catch(() => ({ message: 'Unknown error occurred' }));
      throw new Error(error.message || `HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  protected async request<T>(
    endpoint: string,
    config: RequestConfig = {}
  ): Promise<T> {
    const { retry = this.defaultConfig.retry, retryDelay = this.defaultConfig.retryDelay, ...requestConfig } = config;
    let lastError: Error | null = null;
    
    for (let attempt = 0; attempt <= retry!; attempt++) {
      try {
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
          ...this.defaultConfig,
          ...requestConfig,
          headers: {
            ...this.defaultConfig.headers,
            ...this.getAuthHeader(),
            ...requestConfig.headers,
          },
        });

        return await this.handleResponse<T>(response);
      } catch (error) {
        lastError = error instanceof Error ? error : new Error('Unknown error occurred');
        
        if (attempt === retry || lastError.message.includes('Session expired')) {
          throw lastError;
        }
        
        await this.delay(retryDelay! * Math.pow(2, attempt));
      }
    }

    throw lastError;
  }

  protected get<T>(endpoint: string, config: RequestConfig = {}): Promise<T> {
    return this.request<T>(endpoint, { ...config, method: 'GET' });
  }

  protected post<T>(endpoint: string, data?: any, config: RequestConfig = {}): Promise<T> {
    return this.request<T>(endpoint, {
      ...config,
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  protected put<T>(endpoint: string, data?: any, config: RequestConfig = {}): Promise<T> {
    return this.request<T>(endpoint, {
      ...config,
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  protected delete<T>(endpoint: string, config: RequestConfig = {}): Promise<T> {
    return this.request<T>(endpoint, { ...config, method: 'DELETE' });
  }
}
