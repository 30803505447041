import { Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from '@/layouts/AuthLayout';
import DashboardLayout from '@/layouts/DashboardLayout';
import ProtectedRoute from '@/components/auth/ProtectedRoute';
import PublicRoute from '@/components/auth/PublicRoute';
import Login from '@/pages/auth/Login';
import ForgotPassword from '@/pages/auth/ForgotPassword';
import Dashboard from '@/pages/dashboard/Dashboard';
import Users from '@/pages/admin/users';
import UserForm from '@/pages/admin/users/UserForm';
import Settings from '@/pages/admin/settings/Settings';
import Templates from '@/pages/admin/templates';
import CardsPage from '@/pages/admin/cards';
import CardForm from '@/pages/admin/cards/CardForm';
import TemplateForm from '@/pages/admin/templates/TemplateForm';
import TemplateCategories from '@/pages/admin/template-categories';
import TemplateCategoryForm from '@/pages/admin/template-categories/TemplateCategoryForm';

const AppRoutes = () => {
  return (
    <Routes>
      {/* Root redirect */}
      <Route path="/" element={<Navigate to="/admin" replace />} />

      {/* Public Routes */}
      <Route path="/login" element={
        <PublicRoute>
          <AuthLayout>
            <Login />
          </AuthLayout>
        </PublicRoute>
      } />
      
      <Route path="/forgot-password" element={
        <PublicRoute>
          <AuthLayout>
            <ForgotPassword />
          </AuthLayout>
        </PublicRoute>
      } />

      {/* Protected Routes */}
      <Route path="/admin" element={
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      }>
        <Route index element={<Dashboard />} />
        <Route path="templates" element={<Templates />} />
        <Route path="templates/new" element={<TemplateForm />} />
        <Route path="templates/edit/:id" element={<TemplateForm />} />
        <Route path="template-categories" element={<TemplateCategories />} />
        <Route path="template-categories/create" element={<TemplateCategoryForm />} />
        <Route path="template-categories/:id/edit" element={<TemplateCategoryForm />} />
        <Route path="users" element={<Users />} />
        <Route path="users/new" element={<UserForm />} />
        <Route path="users/edit/:id" element={<UserForm />} />
        <Route path="cards" element={<CardsPage />} />
        <Route path="cards/new" element={<CardForm />} />
        <Route path="cards/edit/:id" element={<CardForm />} />
        <Route path="settings" element={<Settings />} />
      </Route>

      {/* Catch all route */}
      <Route path="*" element={<Navigate to="/admin" replace />} />
    </Routes>
  );
};

export default AppRoutes;
