import { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import {
  LayoutDashboard,
  FileText,
  Users,
  Settings,
  LogOut,
  Menu,
  X,
  Bell,
  Search,
  User,
  CreditCard
} from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Toaster } from "@/components/ui/toaster"

const navigation = [
  { name: 'Dashboard', href: '/admin', icon: LayoutDashboard },
  { name: 'Cards', href: '/admin/cards', icon: CreditCard },
  { name: 'Templates', href: '/admin/templates', icon: FileText },
  { name: 'Template Categories', href: '/admin/template-categories', icon: FileText },
  { name: 'Users', href: '/admin/users', icon: Users },
  { name: 'Settings', href: '/admin/settings', icon: Settings },
];

export default function DashboardLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { logout, user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-background">
      {/* Mobile sidebar toggle */}
      <div className="lg:hidden">
        <Button
          variant="ghost"
          size="icon"
          className="fixed right-4 top-4 z-50"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          {sidebarOpen ? <X className="h-5 w-5" /> : <Menu className="h-5 w-5" />}
        </Button>
      </div>

      {/* Sidebar */}
      <aside
        className={cn(
          "fixed inset-y-0 left-0 z-40 w-64 transform bg-card/95 backdrop-blur-sm shadow-xl transition-all duration-300 ease-in-out lg:translate-x-0",
          "border-r border-border/40",
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        )}
      >
        <div className="flex h-full flex-col">
          {/* Logo */}
          <div className="flex h-16 items-center gap-3 border-b border-border/40 px-6">
            <img 
              src="/logo.svg" 
              alt="Logo" 
              className="h-20 w-20 transition-transform duration-300 hover:scale-110" 
            />
            <span className="bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-lg font-bold text-transparent">
              Cards AI
            </span>
          </div>

          {/* Navigation */}
          <nav className="flex-1 space-y-1 overflow-y-auto p-4 scrollbar-none">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={cn(
                  "group relative flex items-center rounded-lg px-4 py-2.5 text-sm font-medium transition-all duration-200",
                  "hover:bg-primary/5",
                  "active:scale-[0.98]",
                  "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary/20",
                  location.pathname === item.href
                    ? "bg-primary/10 text-primary shadow-sm"
                    : "text-muted-foreground hover:text-primary"
                )}
              >
                <item.icon 
                  className={cn(
                    "mr-3 h-5 w-5 transition-all duration-200",
                    "group-hover:scale-110 group-hover:rotate-3",
                    location.pathname === item.href ? "text-primary" : "text-muted-foreground"
                  )} 
                />
                <span className="font-medium">{item.name}</span>
                {location.pathname === item.href && (
                  <span className="absolute inset-y-1 left-0 w-1 rounded-r-full bg-primary shadow-md shadow-primary/20" />
                )}
              </Link>
            ))}
          </nav>

          {/* Logout button */}
          <div className="border-t border-border/40 p-4">
            <Button
              variant="ghost"
              className={cn(
                "w-full justify-start rounded-lg px-4 py-2.5 text-sm font-medium",
                "text-muted-foreground transition-colors",
                "hover:bg-destructive/10 hover:text-destructive",
                "active:scale-[0.98]",
                "group"
              )}
              onClick={logout}
            >
              <LogOut className="mr-3 h-5 w-5 transition-transform duration-200 group-hover:scale-110 group-hover:-rotate-3" />
              <span className="font-medium">Logout</span>
            </Button>
          </div>
        </div>
      </aside>

      {/* Main content */}
      <main
        className={cn(
          "min-h-screen w-full transition-all duration-150 ease-in-out",
          "lg:pl-72"
        )}
      >
        {/* Header */}
        <header className={cn(
          "sticky top-0 z-30 w-full border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60",
          "transition-all duration-200",
          scrolled && "shadow-sm"
        )}>
          <div className="flex h-16 items-center gap-4 px-4 lg:px-6">
            <div className="flex-1">
              <div className="relative w-full max-w-sm">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder="Search..."
                  className="w-full pl-9 bg-transparent"
                />
              </div>
            </div>
            
            <div className="flex items-center gap-4">
              <Button variant="ghost" size="icon" className="relative">
                <Bell className="h-5 w-5" />
                <span className="absolute right-2.5 top-2.5 h-2 w-2 rounded-full bg-primary" />
              </Button>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="icon" className="rounded-full">
                    <User className="h-5 w-5" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-56">
                  <DropdownMenuLabel>My Account</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>Profile</DropdownMenuItem>
                  <DropdownMenuItem>Settings</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem className="text-destructive" onClick={logout}>
                    Log out
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </header>

        <div className="p-4 lg:p-6">
          <Outlet />
        </div>
      </main>
      <Toaster />
    </div>
  );
}
