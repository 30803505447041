import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '@/services/auth.service';
import { AuthState, User } from '@/types/auth.types';
import storage from '@/utils/storage'; // Assuming storage is imported from this file

interface AuthContextValue extends AuthState {
  logout: () => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<AuthState>({
    isAuthenticated: !!storage.get('access_token'),
    isLoading: true,
    user: (() => {
      const userStr = storage.get('user');
      if (!userStr) return null;
      try {
        return JSON.parse(userStr);
      } catch {
        return null;
      }
    })(),
  });
  const navigate = useNavigate();

  // Check authentication status whenever the component mounts
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const isAuthenticated = await authService.isAuthenticated();
        const user = await authService.getUser();
        setState({
          isAuthenticated,
          user,
          isLoading: false,
        });
      } catch (error) {
        console.error(error);
        setState({
          isAuthenticated: false,
          user: null,
          isLoading: false,
        });
      }
    };

    checkAuth();
  }, []);

  // Watch for token changes
  useEffect(() => {
    const checkToken = async () => {
      try {
        const isAuthenticated = await authService.isAuthenticated();
        const user = await authService.getUser();
        setState(prev => {
          if (prev.isAuthenticated !== isAuthenticated || prev.user?.id !== user?.id) {
            return {
              ...prev,
              isAuthenticated,
              user,
            };
          }
          return prev;
        });
      } catch (error) {
        console.error(error);
      }
    };

    // Handle session expiration
    const handleSessionExpired = () => {
      setState({
        isAuthenticated: false,
        isLoading: false,
        user: null,
      });
      navigate('/login', { replace: true });
    };

    window.addEventListener('auth:sessionExpired', handleSessionExpired);
    const interval = setInterval(checkToken, 1000);
    
    return () => {
      window.removeEventListener('auth:sessionExpired', handleSessionExpired);
      clearInterval(interval);
    };
  }, [navigate]);

  const logout = async () => {
    try {
      await authService.logout();
      setState({
        isAuthenticated: false,
        isLoading: false,
        user: null,
      });
      navigate('/login', { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      await authService.forgotPassword(email);
    } catch (error) {
      throw error;
    }
  };

  const value: AuthContextValue = {
    ...state,
    logout,
    forgotPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
