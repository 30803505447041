import { ApiService } from './api.service';
import {
  CreateUserDto,
  UpdateUserDto,
  UserResponseDto,
  UsersResponseDto,
  UserQueryParams,
} from '@/dtos/user.dto';

class UserService extends ApiService {
  private static instance: UserService;
  private readonly endpoint = '/admin/users';

  private constructor() {
    super('/api');
  }

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  public async getUsers(params?: UserQueryParams): Promise<UsersResponseDto> {
    const queryParams = new URLSearchParams();
    if (params?.page) queryParams.append('page', params.page.toString());
    if (params?.limit) queryParams.append('limit', params.limit.toString());
    if (params?.search) queryParams.append('search', params.search);
    if (params?.isActive !== undefined) queryParams.append('isActive', params.isActive.toString());
    if (params?.provider) queryParams.append('provider', params.provider);
    if (params?.sortBy) queryParams.append('sortBy', params.sortBy);
    if (params?.sortOrder) queryParams.append('sortOrder', params.sortOrder);

    const query = queryParams.toString();
    return this.get<UsersResponseDto>(`${this.endpoint}${query ? `?${query}` : ''}`);
  }

  public async getUser(id: string): Promise<UserResponseDto> {
    return this.get<UserResponseDto>(`${this.endpoint}/${id}`);
  }

  public async createUser(data: CreateUserDto): Promise<UserResponseDto> {
    return this.post<UserResponseDto>(this.endpoint, data);
  }

  public async updateUser(id: string, data: UpdateUserDto): Promise<UserResponseDto> {
    return this.put<UserResponseDto>(`${this.endpoint}/${id}`, data);
  }

  public async deleteUser(id: string): Promise<void> {
    return this.delete<void>(`${this.endpoint}/${id}`);
  }

  public async bulkDeleteUsers(ids: string[]): Promise<void> {
    return this.post<void>(`${this.endpoint}/bulk-delete`, { ids });
  }

  public async toggleUserStatus(id: string, isActive: boolean): Promise<UserResponseDto> {
    return this.put<UserResponseDto>(`${this.endpoint}/${id}/status`, { isActive });
  }
}

export const userService = UserService.getInstance();
