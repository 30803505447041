import axios from 'axios';
import { ApiService } from './api.service';
import storage from '@/utils/storage';
import {
  TemplateDto,
  TemplateListResponseDto,
  TemplateResponseDto,
  CreateTemplateDto,
  UpdateTemplateDto,
  CategoryDto
} from '@/dtos/template.dto';

export type { TemplateDto, CategoryDto };

interface CategoryResponse {
  id: number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
}

export interface TemplateFilters {
  search?: string;
  page?: number;
  limit?: number;
  categoryId?: number;
}

class TemplateService extends ApiService {
  private static instance: TemplateService;
  protected readonly endpoint = '/admin/templates';
  protected readonly baseEndpoint = '/api';

  private constructor() {
    super('/api');
  }

  public static getInstance(): TemplateService {
    if (!TemplateService.instance) {
      TemplateService.instance = new TemplateService();
    }
    return TemplateService.instance;
  }

  async getTemplates(filters: TemplateFilters = {}): Promise<TemplateListResponseDto> {
    const queryParams = new URLSearchParams();
    if (filters.search) queryParams.append('search', filters.search);
    if (filters.page) queryParams.append('page', filters.page.toString());
    if (filters.limit) queryParams.append('limit', filters.limit.toString());
    if (filters.categoryId) queryParams.append('categoryId', filters.categoryId.toString());
    
    const query = queryParams.toString();
    return this.request<TemplateListResponseDto>(
      `${this.endpoint}${query ? `?${query}` : ''}`
    );
  }

  public async getTemplate(id: string): Promise<TemplateResponseDto> {
    return this.request<TemplateResponseDto>(`${this.endpoint}/${id}`);
  }

  private getFormDataConfig(): any {
    return {
      headers: {
        'Authorization': `Bearer ${storage.get('access_token')}`,
      }
    };
  }

  public async createTemplate(formData: FormData): Promise<TemplateResponseDto> {
    const response = await axios.post<TemplateResponseDto>(
      `${this.baseEndpoint}${this.endpoint}`,
      formData,
      this.getFormDataConfig()
    );
    return response.data;
  }

  public async updateTemplate(id: string, formData: FormData): Promise<TemplateResponseDto> {
    try {
      const response = await axios.patch<TemplateResponseDto>(
        `${this.baseEndpoint}${this.endpoint}/${id}`,
        formData,
        this.getFormDataConfig()
      );
      return response.data;
    } catch (error) {
      console.error('Update template error:', error);
      throw error;
    }
  }

  public async deleteTemplate(id: string): Promise<void> {
    await this.request(`${this.endpoint}/${id}`, {
      method: 'DELETE',
    });
  }

  public async getCategories(): Promise<CategoryDto[]> {
    return this.request<CategoryDto[]>('/admin/templates/categories')
      .catch(() => []);
  }
}

export const templateService = TemplateService.getInstance();
