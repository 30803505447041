import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Separator } from "@/components/ui/separator";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { useToast } from '@/hooks/use-toast';
import { ArrowLeft, Image as ImageIcon, MessageSquare, Wand2 } from 'lucide-react';
import { cardService } from '@/services/card.service';
import { CreateCardDto } from '@/dtos/card.dto';

const cardFormSchema = z.object({
  prompt: z.string().min(2, 'Prompt must be at least 2 characters'),
  message: z.string().min(2, 'Message must be at least 2 characters'),
  imageUrl: z.string().url('Please enter a valid image URL').optional().or(z.literal('')),
});

type CardFormData = z.infer<typeof cardFormSchema>;

export default function CardForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const isEditing = Boolean(id);
  const [loading, setLoading] = useState(false);

  const form = useForm<CardFormData>({
    resolver: zodResolver(cardFormSchema),
    defaultValues: {
      prompt: '',
      message: '',
      imageUrl: '',
    },
  });

  useEffect(() => {
    if (id) {
      fetchCard();
    }
  }, [id]);

  const fetchCard = async () => {
    if (!id) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Card ID is required",
      });
      return;
    }
    
    try {
      setLoading(true);
      const result = await cardService.getCard(id);
      form.reset({
        prompt: result.prompt || '',
        message: result.message || '',
        imageUrl: result.imageUrl || '',
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch card details",
      });
      navigate('/admin/cards');
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: CardFormData) => {
    try {
      setLoading(true);
      if (id) {
        await cardService.updateCard(id, data);
        toast({
          title: "Success",
          description: "Card updated successfully",
        });
      } else {
        await cardService.createCard(data);
        toast({
          title: "Success",
          description: "Card created successfully",
        });
      }
      navigate('/admin/cards');
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to save card",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => navigate('/admin/cards')}
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h1 className="text-3xl font-bold tracking-tight">
            {isEditing ? 'Edit Card' : 'New Card'}
          </h1>
        </div>
      </div>

      <Separator />

      <div className="grid gap-6">
        <Card className="p-6">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <div className="grid gap-6">
                <FormField
                  control={form.control}
                  name="prompt"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Prompt</FormLabel>
                      <FormControl>
                        <div className="relative">
                          <Wand2 className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                          <Input className="pl-8" {...field} />
                        </div>
                      </FormControl>
                      <FormDescription>
                        Enter the prompt to generate the card image
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Message</FormLabel>
                      <FormControl>
                        <div className="relative">
                          <MessageSquare className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                          <Input className="pl-8" {...field} />
                        </div>
                      </FormControl>
                      <FormDescription>
                        Enter the message to display on the card
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="imageUrl"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Image URL</FormLabel>
                      <FormControl>
                        <div className="relative">
                          <ImageIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                          <Input className="pl-8" {...field} />
                        </div>
                      </FormControl>
                      <FormDescription>
                        Enter the URL of the card image (optional)
                      </FormDescription>
                      <FormMessage />
                      {field.value && (
                        <div className="mt-2">
                          <img
                            src={field.value}
                            alt="Preview"
                            className="max-w-xs rounded-lg shadow-md"
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.src = '/placeholder-image.png';
                              target.className = 'max-w-xs rounded-lg shadow-md opacity-50';
                            }}
                          />
                        </div>
                      )}
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex justify-end space-x-2">
                <Button
                  variant="outline"
                  onClick={() => navigate('/admin/cards')}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={loading}>
                  {loading && <ImageIcon className="mr-2 h-4 w-4 animate-spin" />}
                  {isEditing ? 'Update' : 'Create'} Card
                </Button>
              </div>
            </form>
          </Form>
        </Card>
      </div>
    </div>
  );
}
