class MemoryStorage implements Storage {
  private storage: Map<string, string>;

  constructor() {
    this.storage = new Map();
  }

  get length(): number {
    return this.storage.size;
  }

  key(index: number): string | null {
    return Array.from(this.storage.keys())[index] || null;
  }

  getItem(key: string): string | null {
    return this.storage.get(key) || null;
  }

  setItem(key: string, value: string): void {
    this.storage.set(key, value);
  }

  removeItem(key: string): void {
    this.storage.delete(key);
  }

  clear(): void {
    this.storage.clear();
  }
}

// Create a singleton instance
const memoryStorage = new MemoryStorage();

export default memoryStorage;
