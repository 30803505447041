import { ApiService } from './api.service';
import {
  TemplateCategoryDto,
  CreateTemplateCategoryDto,
  UpdateTemplateCategoryDto,
  TemplateCategoryQueryParams,
  TemplateCategoryResponseDto
} from '@/dtos/template-category.dto';

class TemplateCategoryService extends ApiService {
  async getTemplateCategories(params: TemplateCategoryQueryParams): Promise<TemplateCategoryResponseDto> {
    const queryParams = new URLSearchParams();
    if (params.search) queryParams.append('search', params.search);
    if (params.limit) queryParams.append('limit', params.limit.toString());
    if (params.page) queryParams.append('page', params.page.toString());
    if (params.sortBy) queryParams.append('sortBy', params.sortBy);
    if (params.sortOrder) queryParams.append('sortOrder', params.sortOrder);

    return this.request<TemplateCategoryResponseDto>(
      `/admin/template-categories?${queryParams.toString()}`,
      { method: 'GET' }
    );
  }

  async getTemplateCategory(id: number): Promise<TemplateCategoryDto> {
    return this.request<TemplateCategoryDto>(`/admin/template-categories/${id}`, {
      method: 'GET',
    });
  }

  async createTemplateCategory(data: CreateTemplateCategoryDto): Promise<TemplateCategoryDto> {
    return this.request<TemplateCategoryDto>('/admin/template-categories', {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  async updateTemplateCategory(id: number, data: UpdateTemplateCategoryDto): Promise<TemplateCategoryDto> {
    return this.request<TemplateCategoryDto>(`/admin/template-categories/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  async deleteTemplateCategory(id: number): Promise<void> {
    return this.request<void>(`/admin/template-categories/${id}`, {
      method: 'DELETE',
    });
  }
}

export const templateCategoryService = new TemplateCategoryService();
