import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Search, Plus, MoreHorizontal } from 'lucide-react';
import { UserTableSkeleton } from "@/components/skeletons/UserTableSkeleton";
import { userService } from '@/services/user.service';
import { UserResponseDto, UsersResponseDto, UserQueryParams } from '@/dtos/user.dto';
import { cn } from '@/lib/utils';

interface DeleteDialogState {
  open: boolean;
  userId: string | null;
  loading: boolean;
}

export default function UsersPage() {
  const [users, setUsers] = useState<UserResponseDto[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [providerFilter, setProviderFilter] = useState<string>('all');
  const [deleteDialog, setDeleteDialog] = useState<DeleteDialogState>({
    open: false,
    userId: null,
    loading: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const queryParams: UserQueryParams = {
        search: searchTerm,
        page: currentPage,
        limit: rowsPerPage,
        isActive: statusFilter === 'all' ? undefined : statusFilter === 'active',
        sortBy: 'name',
        sortOrder: 'asc',
        provider: providerFilter === 'all' ? undefined : providerFilter,
      };
      const result = await userService.getUsers(queryParams);
      setUsers(result.items);
      setTotalRows(result.meta.totalItems);
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to fetch users. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  // Fetch users when filters change
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchUsers();
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm, statusFilter, providerFilter, currentPage, rowsPerPage]);

  const handleDelete = async (userId: string) => {
    try {
      setDeleteDialog(prev => ({ ...prev, loading: true }));
      await userService.deleteUser(userId);

      toast({
        title: 'Success',
        description: 'User deleted successfully',
      });

      fetchUsers();
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to delete user. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setDeleteDialog({ open: false, userId: null, loading: false });
    }
  };

  const uniqueProviders = useMemo(() => {
    return [...new Set(users.map(user => user.provider))].sort();
  }, [users]);

  const columns = useMemo(() => ([
    {
      name: '#',
      width: '70px',
      selector: (row: UserResponseDto) => '',
      cell: (row: UserResponseDto) => {
        const index = users.indexOf(row);
        return (
          <span className="text-muted-foreground">
            {(currentPage - 1) * rowsPerPage + index + 1}
          </span>
        );
      },
    },
    {
      name: 'Name',
      selector: (row: UserResponseDto) => row.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: UserResponseDto) => row.email,
      sortable: true,
    },
    {
      name: 'Provider',
      selector: (row: UserResponseDto) => row.provider,
      sortable: true,
      cell: (row: UserResponseDto) => (
        <span className="capitalize">{row.provider}</span>
      ),
    },
    {
      name: 'Status',
      selector: (row: UserResponseDto) => row.isActive,
      sortable: true,
      cell: (row: UserResponseDto) => (
        <span
          className={cn(
            "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium",
            row.isActive
              ? "bg-green-100 text-green-800"
              : "bg-red-100 text-red-800"
          )}
        >
          {row.isActive ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      name: 'Actions',
      selector: (row: UserResponseDto) => '',
      cell: (row: UserResponseDto) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              className="h-8 w-8 p-0 hover:bg-background"
            >
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => navigate(`edit/${row.id}`)}
            >
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              className="text-red-600"
              onClick={() => setDeleteDialog({ open: true, userId: row.id, loading: false })}
            >
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
      width: '100px'
    },
  ]) as TableColumn<UserResponseDto>[], [navigate, currentPage, rowsPerPage, users]);

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: 'var(--background)',
        borderBottomColor: 'var(--border)',
      },
    },
    headCells: {
      style: {
        fontSize: '14px',
        fontWeight: '600',
        color: 'var(--muted-foreground)',
      },
    },
    rows: {
      style: {
        fontSize: '14px',
        '&:hover': {
          backgroundColor: 'var(--muted)',
          cursor: 'pointer',
        },
      },
    },
  };

  return (
    <>
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-bold">Users</h1>
          <Button onClick={() => navigate('new')}>
            <Plus className="mr-2 h-4 w-4" />
            Add User
          </Button>
        </div>

        <Card className="overflow-hidden p-6">
          <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 mb-6">
            <div className="relative flex-1">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
                <Input
                  placeholder="Search by name or email..."
                  className="pl-10"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            
            <div className="flex space-x-4">
              <Select
                value={statusFilter}
                onValueChange={setStatusFilter}
              >
                <SelectTrigger className="w-[140px]">
                  <SelectValue placeholder="Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Status</SelectItem>
                  <SelectItem value="active">Active</SelectItem>
                  <SelectItem value="inactive">Inactive</SelectItem>
                </SelectContent>
              </Select>

              <Select
                value={providerFilter}
                onValueChange={setProviderFilter}
              >
                <SelectTrigger className="w-[140px]">
                  <SelectValue placeholder="Provider" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Providers</SelectItem>
                  {uniqueProviders.map((provider) => (
                    <SelectItem key={provider} value={provider} className="capitalize">
                      {provider}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              {(statusFilter !== 'all' || providerFilter !== 'all' || searchTerm) && (
                <Button
                  variant="ghost"
                  onClick={() => {
                    setStatusFilter('all');
                    setProviderFilter('all');
                    setSearchTerm('');
                  }}
                  className="px-2"
                >
                  Clear Filters
                </Button>
              )}
            </div>
          </div>

          <div className="relative rounded-md border">
            <DataTable
              {...{
                columns: columns as any,
                data: users,
                progressPending: loading,
                progressComponent: <UserTableSkeleton />,
                pagination: true,
                paginationServer: true,
                paginationTotalRows: totalRows,
                paginationPerPage: rowsPerPage,
                paginationDefaultPage: currentPage,
                onChangePage: (page: number) => setCurrentPage(page),
                onChangeRowsPerPage: (currentRowsPerPage: number) => {
                  setRowsPerPage(currentRowsPerPage);
                  setCurrentPage(1);
                },
                customStyles,
                noDataComponent: (
                  <div className="p-4 text-center text-muted-foreground">
                    No users found
                  </div>
                ),
              } as any}
            />
          </div>
        </Card>
      </div>

      <Dialog open={deleteDialog.open} onOpenChange={(open) => !deleteDialog.loading && setDeleteDialog(prev => ({ ...prev, open }))}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogDescription>
              This action cannot be undone. This will permanently delete the user's account and remove their data from our servers.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => !deleteDialog.loading && setDeleteDialog(prev => ({ ...prev, open: false }))}
              disabled={deleteDialog.loading}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={() => deleteDialog.userId && handleDelete(deleteDialog.userId)}
              disabled={deleteDialog.loading}
            >
              {deleteDialog.loading ? 'Deleting...' : 'Delete'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
