import { ApiService } from './api.service';
import { 
  CardDto, 
  CreateCardDto, 
  UpdateCardDto, 
  CardResponseDto,
  CardListResponseDto 
} from '@/dtos/card.dto';

interface GetCardsParams {
  search?: string;
  page?: number;
  limit?: number;
}

class CardService extends ApiService {
  private static instance: CardService;
  private readonly endpoint = '/admin/cards';

  private constructor() {
    super('/api');
  }

  public static getInstance(): CardService {
    if (!CardService.instance) {
      CardService.instance = new CardService();
    }
    return CardService.instance;
  }

  async getCards(params: GetCardsParams = {}): Promise<CardListResponseDto> {
    const queryParams = new URLSearchParams();
    if (params.search) queryParams.append('search', params.search);
    if (params.page) queryParams.append('page', params.page.toString());
    if (params.limit) queryParams.append('limit', params.limit.toString());
    
    const query = queryParams.toString();
    return this.get<CardListResponseDto>(
      `${this.endpoint}${query ? `?${query}` : ''}`
    );
  }

  async getCard(id: string): Promise<CardResponseDto> {
    return this.request<CardResponseDto>(`${this.endpoint}/${id}`);
  }

  async createCard(data: CreateCardDto): Promise<CardResponseDto> {
    return this.request<CardResponseDto>(this.endpoint, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async updateCard(id: string, data: UpdateCardDto): Promise<CardResponseDto> {
    return this.request<CardResponseDto>(`${this.endpoint}/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async deleteCard(id: string): Promise<void> {
    await this.request(`${this.endpoint}/${id}`, {
      method: 'DELETE',
    });
  }
}

export const cardService = CardService.getInstance();
